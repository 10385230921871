import React from 'react';
import { graphql,  useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { css } from '@emotion/react';
import mq from '@/styles/mq';



const useImages = () => {
    const data = useStaticQuery(query);
  
    return (lang: string, width = '25px', height = '25px') => {
        switch (lang) {
            case 'fr':
                return (
                    <Img 
                      css={css`
                        
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `} 
                      fluid={data.fr.childImageSharp.fluid} 
                    />
                );
            case 'en':
                return (
                  <Img 
                    css={css`
                      
                      margin: 0 5px;
                      width: ${width};
                      height: ${height};
                    `} 
                    fluid={data.en.childImageSharp.fluid} 
                  />
                );
            case 'nl':
                return (
                    <Img 
                      css={css`
                          
                          margin: 0 5px;
                          width: ${width};
                          height: ${height};
                        `}
                      fluid={data.nl.childImageSharp.fluid} 
                    />
                );
            case 'mail':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.mail.childImageSharp.fluid} 
                />
              );
            case 'phone':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.phone.childImageSharp.fluid} 
                />
              );
            case 'facebook':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.facebook.childImageSharp.fluid} 
                />
              );
            case 'instagram':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.instagram.childImageSharp.fluid} 
                />
              );
            case 'twitter':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.twitter.childImageSharp.fluid} 
                />
              );
            case 'pinterest':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.pinterest.childImageSharp.fluid} 
                />
              );
            case 'linkedin':
              return (
                <Img 
                  css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                  fluid={data.linkedin.childImageSharp.fluid} 
                />
              );
            case 'footerfacebook':
              return (
                <Img
                  css={css`
                          margin: 0 5px;
                          width: ${width};
                          height: ${height};
                        `}
                  fluid={data.footerfacebook.childImageSharp.fluid}
                />
              );
            case 'footerinstagram':
              return (
                <Img
                  css={css`
                          margin: 0 5px;
                          width: ${width};
                          height: ${height};
                        `}
                  fluid={data.footerinstagram.childImageSharp.fluid}
                />
              );
            case 'footertwitter':
              return (
                <Img
                  css={css`
                          margin: 0 5px;
                          width: ${width};
                          height: ${height};
                        `}
                  fluid={data.footertwitter.childImageSharp.fluid}
                />
              );
            case 'footerlinkedin':
              return (
                <Img
                  css={css`
                          margin: 0 5px;
                          width: ${width};
                          height: ${height};
                        `}
                  fluid={data.footerlinkedin.childImageSharp.fluid}
                />
              );
          case 'footerpinterest':
            return (
              <Img
                css={css`
                        margin: 0 5px;
                        width: ${width};
                        height: ${height};
                      `}
                fluid={data.footerpinterest.childImageSharp.fluid}
              />
            );
            default:
                return;
        }
    };
};

export default useImages;

const query = graphql`
  query {
    fr: file(relativePath: { eq: "lang/fr.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  en: file(relativePath: { eq: "lang/en.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  nl: file(relativePath: { eq: "lang/nl.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  mail: file(relativePath: { eq: "header/mail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    phone: file(relativePath: { eq: "header/phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facebook: file(relativePath: { eq: "header/facebook.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instagram: file(relativePath: { eq: "header/instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twitter: file(relativePath: { eq: "header/twitter.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    linkedin: file(relativePath: { eq: "header/linkedin.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pinterest: file(relativePath: { eq: "header/pinterest.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerfacebook: file(relativePath: { eq: "footer/footerfacebook.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerinstagram: file(relativePath: { eq: "footer/footerinstagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerlinkedin: file(relativePath: { eq: "footer/footerlinkedin.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footertwitter: file(relativePath: { eq: "footer/footertwitter.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerpinterest: file(relativePath: { eq: "footer/footerpinterest.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}

`;